<template>
  <div>
    <tp-autocomplete
      v-model="internalValue"
      :items="filteredPosts"
      :search-input.sync="postSearchKey"
      class="tp-filter-autocomplete"
      clearable
      item-text="title"
      item-value="id"
      no-data-text="Không có dữ liệu"
      no-filter
      placeholder="Tìm kiếm bài viết"
      single-line
    ></tp-autocomplete>
  </div>
</template>

<script>
import { LIST_MODEL_SITES } from "@/core/constant";

export default {
  name: "AutocompleteArticle",

  props: {
    article: { type: Object, default: () => ({}) },
    value: { type: Number, default: 0 },
    sites: { type: Array, required: true }
  },

  data() {
    return {
      postSearchKey: null,
      LIST_MODEL_SITES: LIST_MODEL_SITES,
      posts: []
    };
  },

  watch: {
    postSearchKey(val) {
      if (val && val.length > 0) {
        this.getPosts();
      }
    }
  },

  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },

    filteredPosts() {
      if (this.internalValue && this.article) {
        const selectedPost = this.posts.find(
          item => item.id === this.internalValue
        );

        if (!selectedPost) return [{ ...this.article }, ...this.posts];
      }

      return this.posts;
    }
  },

  methods: {
    async getPosts() {
      this.posts = await this.$store.dispatch("POST/getPosts", {
        cur_page: 1,
        per_page: 50,
        search: this.postSearchKey,
        status: 1,
        sites: this.sites
      });
    }
  }
};
</script>
