<template>
  <Mentionable :keys="['@']" :items="attributes" offset="6">
    <tp-text-field
      v-model="internalValue"
      dense
      outlined
      hide-details
    ></tp-text-field>

    <template #no-result>
      <div class="dim">
        Không có kết quả
      </div>
    </template>
  </Mentionable>
</template>

<script>
import { Mentionable } from "vue-mention";

export default {
  components: {
    Mentionable
  },
  props: {
    value: {
      type: String,
      default: ""
    }
  },

  computed: {
    attributes() {
      return this.$store.getters["PRODUCT_TYPE/attributes"];
    },

    internalValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss">
.mention-item {
  padding: 4px 10px;
}

.mention-selected {
  background: $color-primary;
  color: white;
}
</style>
